const moment = require('moment');
const { DATETIME_FORMAT } = require('@/utils/constants');
const DATE_FORMAT = 'DD/MM/YYYY';
const YEAR_MONTH_DAY_FORMAT = 'YYYY-MM-DD';
const DATETIME_DB_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const CLIENT_DATE_TIME = 'DD/MM/YYYY HH:mm:ss';

const DP_CONFIG = {
  date: {
    format: 'DD/MM/YYYY',
    useCurrent: false,
    showClear: true,
    showClose: true,
    minDate: false,
    maxDate: false,
    locale: 'vi',
    sideBySide: true,
    allowInputToggle: true,
  },
  time: {
    format: 'LT',
    useCurrent: true,
    sideBySide: true,
    locale: 'vi',
    allowInputToggle: true,
  },
};

const DP_CONFIG_DATETIME = {
  date: {
    format: DATETIME_FORMAT,
    useCurrent: false,
    showClear: true,
    showClose: true,
    minDate: false,
    maxDate: false,
    sideBySide: true,
    locale: 'vi',
  },
};

const formatDate = (date, format) => {
  return moment(date).format(format || 'YYYY-MM-DD');
};

const formatSpecificDate = (date, currentFormat, newFormat) => {
  return date ? moment(date, currentFormat).format(newFormat) : null;
};

const formatToISODate = (date, currentFormat, toFormat = null) => {
  return moment(date, currentFormat).format(toFormat);
};

const getSubtractDate = (subtract = 0, format = DATE_FORMAT) => {
  return moment()
    .subtract(subtract, 'days')
    .format(format);
};

const getAddDate = (add = 0, format = DATE_FORMAT) => {
  return moment()
    .add(add, 'days')
    .format(format);
};

const getRemainingDays = (startDate, endDate, format = DATETIME_FORMAT) => {
  return moment(endDate, format).diff(moment(startDate, format), 'days');
};

const getCurrentDate = (format = DATE_FORMAT) => {
  return moment().format(format);
}

const getStartOfDay = (date, format = DATE_FORMAT) => {
  const isValidDate = moment(date, format).isValid();
  return isValidDate
    ? moment(date, format)
        .startOf('day')
        .format(DATETIME_DB_FORMAT)
    : null;
};

const getEndOfDay = (date, format = DATE_FORMAT) => {
  const isValidDate = moment(date, format).isValid();
  return isValidDate
    ? moment(date, format)
        .endOf('day')
        .format(DATETIME_DB_FORMAT)
    : null;
};

/**
 * Formats a date from one format to another using Moment.js library.
 * @param {string} date - The date string to be formatted.
 * @param {string} [currentFormat=DATE_FORMAT] - The current format of the date string. Default is DD/MM/YYYY.
 * @param {string} [format=YEAR_MONTH_DAY_FORMAT] - The desired format for the output date string. Default is YYYY-MM-DD.
 * @returns {string|null} The formatted date string if valid; otherwise, null.
 */
const formatDBDate = (
  date,
  currentFormat = DATE_FORMAT,
  format = YEAR_MONTH_DAY_FORMAT,
) => {
  const isValidDate = moment(date, format).isValid();
  return isValidDate ? moment(date, currentFormat).format(format) : null;
};

/**
 * Checks if one date is after another, using a specified format for parsing date strings.
 *
 * @param {Date | string | moment.Moment} date1 - The first date for the comparison.
 * @param {Date | string | moment.Moment} date2 - The second date for the comparison.
 * @param {string} format - The format to be used for parsing the date strings (default is YYYY-MM-DD).
 * @returns {boolean} Returns true if date1 is after date2, false otherwise.
 */
const checkIsAfterTime = (
  date1,
  date2,
  format = YEAR_MONTH_DAY_FORMAT,
) => {
  return moment(date1, format).isAfter(moment(date2, format));
};

module.exports = {
  formatDate,
  getSubtractDate,
  getAddDate,
  formatToISODate,
  DP_CONFIG,
  getRemainingDays,
  DP_CONFIG_DATETIME,
  YEAR_MONTH_DAY_FORMAT,
  formatSpecificDate,
  getCurrentDate,
  DATETIME_DB_FORMAT,
  getStartOfDay,
  getEndOfDay,
  DATE_FORMAT,
  formatDBDate,
  CLIENT_DATE_TIME,
  checkIsAfterTime,
};
